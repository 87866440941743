define("ui/authenticated/cluster/security/members/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model(params) {
      const store = Ember.get(this, 'globalStore');
      return Ember.RSVP.hash({
        role: store.find('clusterroletemplatebinding', params.role_id),
        roles: store.find('roletemplate', null, {
          filter: {
            hidden: false,
            context: 'cluster'
          }
        })
      });
    }

  });

  _exports.default = _default;
});