define("ui/authenticated/cluster/projects/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    access: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    model(params) {
      const store = Ember.get(this, 'globalStore');
      return Ember.RSVP.hash({
        me: Ember.get(this, 'access.principal'),
        project: store.find('project', params.project_id),
        projectRoleTemplateBindings: store.find('projectRoleTemplateBinding'),
        projects: store.findAll('project'),
        roles: Ember.get(this, 'roleTemplateService').get('allFilteredRoleTemplates'),
        users: store.find('user', null, {
          forceReload: true
        })
      }).then(hash => {
        Ember.set(hash, 'project', Ember.get(hash, 'project').clone());
        return hash;
      });
    }

  });

  _exports.default = _default;
});